import React from 'react'
import { Button } from './Button'
import '../App.scss'
import './HeroSection.scss'

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-coverflow';

function HeroSection() {
    return (
        <section className='hero-container' id="heroe">
            <video src='/videos/meeting.mp4' autoPlay loop muted />
            <Swiper
                spaceBetween={2}
                modules={[Pagination, Navigation, Scrollbar, A11y, Autoplay]}
                autoplay={{ delay: 2500, disableOnInteraction: false }}
                navigation={false}
                effect='coverflow'
                centeredSlides={true}
                slidesPerView={1}
                loop={true}
                speed={1800}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    slideShadows: true,
                }}
                className="about-us-swiper"
                pagination={{ clickable: true, }}
            >
                <SwiperSlide className='swiper-slide-about-us'>
                    <h1><span className='highlight-text'>Maximize efficiency and profits</span> with our cutting-edge tech solutions</h1>
                    <p>AI-driven innovation in the cloud: automating the future</p>
                </SwiperSlide>
                <SwiperSlide className='swiper-slide-about-us'>
                    <h1>We are specialists in providing AI and cloud computing solutions</h1>
                    <p>to help businesses and organizations <span className='highlight-text'>stay ahead in today's fast-paced digital world.</span></p>
                </SwiperSlide>
                <SwiperSlide className='swiper-slide-about-us'>
                    <h1>We believe in the power of technology to <span className='highlight-text'>transform the way we live and work</span></h1>
                    <p>we're dedicated to helping our clients harness that power to achieve their goals.</p>
                </SwiperSlide>
                <SwiperSlide className='swiper-slide-about-us'>
                    <h1>Our team of experienced engineers and data scientists have a proven track record</h1>
                    <p><span className='highlight-text'>delivering innovative solutions</span> that drive real-world results.</p>
                </SwiperSlide>
                <SwiperSlide className='swiper-slide-about-us'>
                    <h1><span className='highlight-text'>We focus our work on our customer needs</span></h1>
                    <p>and on delivering value at every step of the way.</p>
                </SwiperSlide>
            </Swiper>
            <div className="hero-btns">
                <Button
                    // @ts-ignore
                    className="btns" path="contact-us" buttonStyle="btn--outline" buttonSize="btn--large">Get expert guidance
                </Button>
            </div>
        </section>
    )
}

export default HeroSection