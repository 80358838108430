import React, { useState } from 'react'
import './ContactUs.scss'
import { AiOutlineWhatsApp, AiOutlineMail } from 'react-icons/ai'
import usePageBottom from "./usePageBottom";
import { motion, MotionConfig } from "framer-motion"
import { Grid } from '@mui/material';
import { useMailChimp } from './mailchimp';
import Calendly from './Calendy';





function ContactUs() {
    const reachedBottom = usePageBottom();

    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [message, setMessage] = useState("")
    const { subscribe, status, error, value } = useMailChimp("https://gmail.us21.list-manage.com/subscribe/post?")


    const handleSubmit = async (event) => {
        event.preventDefault()

        const data = new FormData(event.target)
        const U = "617df138aa100bde24258f97b"
        const ID = "16bc97dfd5"
        data.append("u", U)
        data.append("id", ID)

        var object = {};
        data.forEach((value, key) => object[key] = value);

        if (object.EMAIL === '' || object.FNAME === '' || object.MESSAGE === '') return

        try {
            // console.log(object.FNAME)

            subscribe(object)

            setEmail('')
            setName('')
            setMessage('')
            // if (status === "SUCCESS") {
            //     console.log('success')
            //     setEmail('')
            //     setName('')
            //     setMessage('')
            // }
        } catch (error) {
            return
        }
    }

    const handleEmailChange = (event) => {
        const { value } = event.target
        setEmail(value)
    }

    const handleNameChange = (event) => {
        const { value } = event.target
        setName(value)

    }

    const handleMessageChange = (event) => {
        const { value } = event.target
        setMessage(value)
    }

    // console.log("reachedBottom", reachedBottom);
    return (
        <section id="contact-us">
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}
            >
                <div className="contact-us-wrapper">
                    <Grid container spacing={2} className='contact-us-grid'>
                        <Grid item xs={12} sm={12} md={6} className='contact-us-grid-item'>
                            <div className="contact-us-container">

                                <h1 className="contact-us-heading">Contact us</h1>
                                <form onSubmit={handleSubmit} className="contact-us-form">
                                    <label htmlFor="name" className="label-contact-us-form">Name:</label>
                                    <input type="text" id="name" value={name} name="FNAME" onChange={handleNameChange} required className='input-contact-us-form' />

                                    <label htmlFor="email" className="label-contact-us-form">Email:</label>
                                    <input type="email" id="email" value={email} name="EMAIL" onChange={handleEmailChange} required className='input-contact-us-form' />

                                    <label htmlFor="message" className="label-contact-us-form">Message:</label>
                                    <textarea id="message" name="MESSAGE" value={message} onChange={handleMessageChange} required></textarea>

                                    <button className="contact-us-btn">
                                        {status === "SUCCESS" ? "Submitted!" : "Submit"}
                                    </button>
                                </form>

                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} className='contact-us-grid-item-img'>
                            <img src="/images/contact-us.png" alt="" id="contact-us-img" />
                        </Grid>
                    </Grid>

                </div>
                <div className='contact-items'>
                    <div className='contact-item'>< AiOutlineMail className="contact-icon" /> info@softmax-technology.com</div>
                    <div className='contact-item'>< AiOutlineWhatsApp className="contact-icon" /> +57-3160509810</div>
                    <Calendly />
                </div>

            </motion.div>
        </section>
    )
}

export default ContactUs