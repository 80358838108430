import React from 'react'
import { Link } from 'react-scroll'
import './Footer.scss'
import { FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa'
import { Grid } from '@mui/material'

function Footer() {
    return (
        <div id="footer" className="footer-dark">
            <footer>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                >
                    <Grid xs={12} md={3} className="footer-main-sections">
                        <h3 className="footer-header">Development Services</h3>
                        <ul>
                            <li>
                                <Link to="services" className='footer-links'>Web development</Link>
                            </li>
                            <li>
                                <Link to="services" className='footer-links' >Robotic process automation</Link>
                            </li>
                            <li>
                                <Link to="services" className='footer-links' >Data extraction and collection</Link>
                            </li>
                            <li>
                                <Link to="services" className='footer-links' >Cloud computing consultancy</Link>
                            </li>
                        </ul>
                    </Grid>
                    <Grid xs={12} md={3} className="footer-main-sections">
                        <h3 className="footer-header">Training Services</h3>
                        <ul>
                            <li>
                                <Link to="services" className='footer-links' >Machine learning Training</Link>
                            </li>
                            <li>
                                <Link to="services" className='footer-links' >Cloud computing Training</Link>
                            </li>
                            <li>
                                <Link to="services" className='footer-links' >Core programing foundations Training</Link>
                            </li>
                        </ul>
                    </Grid>

                    <Grid xs={12} md={3} className="footer-main-sections">
                        <h3 className="footer-header">Analytical Services</h3>
                        <ul>
                            <li>
                                <Link to="services" className='footer-links' >Data mining and data storytelling</Link>
                            </li>
                            <li>
                                <Link to="services" className='footer-links' >Artificial intelligence consultancy</Link>
                            </li>
                        </ul>
                    </Grid>
                    {/* <Grid item xs={12} md={3} className="footer-main-sections">
                        <h3 className="footer-header">About</h3>
                        <ul>
                            <li>
                                <Link to="#" className='footer-links' >Company</Link>
                            </li>
                            <li>
                                <Link to="#" className='footer-links' >Team</Link>
                            </li>
                            <li>
                                <Link to="#" className='footer-links' >Careers</Link>
                            </li>
                        </ul>
                    </Grid> */}

                </Grid>
                <p className="copyright">Softmax techonology © 2022</p>
                <div className="col item social">
                    <a href="https://www.instagram.com/softmax.technology/" target="_blank" rel="noreferrer">< FaInstagram /></a>
                    <a href="https://www.linkedin.com/company/softmax-technology/" target="_blank" rel="noreferrer">< FaLinkedinIn /></a>
                    <a href="https://twitter.com/softmx_tech" target="_blank" rel="noreferrer">< FaTwitter /></a>
                </div>
            </footer>
        </div>
    )
}

export default Footer