import React from "react";
import { PopupModal } from "react-calendly";
import { TbHandClick } from "react-icons/tb";
import { Link } from "react-router-dom";
import "./Calendy.scss";



class Calendy extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    render() {
        return (
            <div>
                <div className='contact-item'>
                    < TbHandClick className="contact-icon" />
                    <Link to="" onClick={() => this.setState({ isOpen: true })} className='booking-link'>Let's book a meeting</Link>
                    
                </div>

                <PopupModal
                    url="https://calendly.com/softmax-tecnology/asesoria-softmax?hide_gdpr_banner=1"
                    pageSettings={this.props.pageSettings}
                    utm={this.props.utm}
                    prefill={this.props.prefill}
                    onModalClose={() => this.setState({ isOpen: false })}
                    open={this.state.isOpen}
                    rootElement={document.getElementById("root")}
                />
            </div>
        );
    }
}

export default Calendy;