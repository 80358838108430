
import './Technologies.scss'
import '../App.scss'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-coverflow';
import { useEffect, useState } from 'react';
import { ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { motion, MotionConfig } from "framer-motion"

function Technologies() {


    const getNumSlides = (windowWidth) => {
        var numCards = 1.3
        if (windowWidth <= 930) {
            numCards = 1.4
        } else if (windowWidth <= 1100) {
            numCards = 2.7
        } else if (windowWidth <= 1600) {
            numCards = 2.5
        } else if (windowWidth <= 1600) {
            numCards = 2.5
        } else {
            numCards = 3.7
        }
        return numCards
    }

    const [numSlides, setNumSlides] = useState(getNumSlides(window.innerWidth))

    useEffect(() => {
        window.addEventListener("resize", () => setNumSlides(getNumSlides(window.innerWidth)));
    })

    const itemDataBackend = [
        {
            img: '/images/technologies/backend/python.png',
            title: 'python',
        },
        {
            img: '/images/technologies/backend/celery.png',
            title: 'celery',
        },
        {
            img: '/images/technologies/backend/django.png',
            title: 'django',
        },
        {
            img: '/images/technologies/backend/github.png',
            title: 'github',
        },
        {
            img: '/images/technologies/backend/flask.png',
            title: 'flask',
        },
        {
            img: '/images/technologies/backend/drf.png',
            title: 'drf',
        },
        {
            img: '/images/technologies/backend/fastapi.png',
            title: 'fastapi',
        },
        {
            img: '/images/technologies/backend/postgresql.png',
            title: 'postgresql',
        },
        {
            img: '/images/technologies/backend/dynamodb.png',
            title: 'dynamodb',
        },
    ]

    const itemDataFrontend = [
        {
            img: '/images/technologies/frontend/javascript.png',
            title: 'javascript',
        },
        {
            img: '/images/technologies/frontend/react.png',
            title: 'react',
        },
        {
            img: '/images/technologies/frontend/material-ui.png',
            title: 'material',
        },
        {
            img: '/images/technologies/frontend/redux.png',
            title: 'redux',
        },
        {
            img: '/images/technologies/frontend/webpack.png',
            title: 'webpack',
        },
        {
            img: '/images/technologies/frontend/yarn.png',
            title: 'yarn',
        },
    ]

    const itemDataDevOps = [
        {
            img: '/images/technologies/devops/ansible.png',
            title: 'ansible',
        },
        {
            img: '/images/technologies/devops/docker.png',
            title: 'docker',
        },
        {
            img: '/images/technologies/devops/redis.png',
            title: 'redis',
        },
        {
            img: '/images/technologies/devops/sentry.png',
            title: 'sentry',
        },
        {
            img: '/images/technologies/devops/jenkins.png',
            title: 'jenkins',
        },
        {
            img: '/images/technologies/devops/aws-codepipeline.png',
            title: 'codepipeline',
        },
    ]


    const itemDataML = [
        {
            img: '/images/technologies/ml/tensorflow.png',
            title: 'tensorflow',
        },
        {
            img: '/images/technologies/ml/spacy.png',
            title: 'spacy',
        },
        {
            img: '/images/technologies/ml/scikit-learn.png',
            title: 'scikitlearn',
        },
        {
            img: '/images/technologies/ml/sagemaker.png',
            title: 'sagemaker',
        },
        {
            img: '/images/technologies/ml/spark.png',
            title: 'spark',
        },
        {
            img: '/images/technologies/ml/keras.png',
            title: 'keras',
        },
        {
            img: '/images/technologies/ml/xgboost.png',
            title: 'xgboost',
        },
        {
            img: '/images/technologies/ml/pytorch.png',
            title: 'pytorch',
        },
        {
            img: '/images/technologies/ml/pandas.png',
            title: 'pandas',
        },
    ]

    return (
        <section id="technologies">
            <>
                <div className="technologies-container">
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1}}
                >
                    <h1 className="technologies-heading">Technologies</h1>
                    <div className="swiper-container">
                        <Swiper
                            spaceBetween={2}
                            modules={[Pagination, Navigation, Scrollbar, A11y, Autoplay]}
                            autoplay={{ delay: 1800, disableOnInteraction: false }}
                            navigation={false}
                            effect='coverflow'
                            centeredSlides={true}
                            slidesPerView={numSlides}
                            loop={true}
                            coverflowEffect={{
                                rotate: 50,
                                stretch: 0,
                                depth: 100,
                                slideShadows: true,
                            }}
                            className="mySwiper"
                            pagination={{ clickable: true, }}
                        >
                            <SwiperSlide className='swiper-slide-technologies'>
                                <div className="technologies-card">
                                    <h1 className="card-title">Machine Learning</h1>
                                    <ImageList
                                        variant="woven"
                                        cols={3}
                                        gap={12}
                                        sx={{ width: "200px", height: "600px", margin: "auto" }}
                                        className="image-list-container"
                                    >
                                        {itemDataML.map((item) => (
                                            <ImageListItem
                                                key={item.img}
                                            >
                                                <img
                                                    src={`${item.img}?w=150&fit=contain&auto=format`}
                                                    srcSet={`${item.img}?w=150&fit=contain&auto=format&dpr=2 2x`}
                                                    alt={item.title}
                                                    loading="lazy"
                                                    className="tech-image-list-item"
                                                />
                                            </ImageListItem>
                                        ))}
                                    </ImageList>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className='swiper-slide-technologies'>
                                <div className="technologies-card">
                                    <h1 className="card-title">DevOps</h1>
                                    <ImageList
                                        variant="woven"
                                        cols={3}
                                        gap={12}
                                        sx={{ width: "200px", height: "600px", margin: "auto" }}
                                        className="image-list-container"
                                    >
                                        {itemDataDevOps.map((item) => (
                                            <ImageListItem
                                                key={item.img}
                                            >
                                                <img
                                                    src={`${item.img}?w=auto&fit=contain&auto=format`}
                                                    srcSet={`${item.img}?w=auto&fit=contain&auto=format&dpr=2 2x`}
                                                    alt={item.title}
                                                    loading="lazy"
                                                    className="tech-image-list-item"
                                                />
                                            </ImageListItem>
                                        ))}
                                    </ImageList>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className='swiper-slide-technologies'>
                                <div className="technologies-card">
                                    <h1 className="card-title">Front-end</h1>
                                    <ImageList
                                        variant="woven"
                                        cols={3}
                                        gap={12}
                                        sx={{ width: "200px", height: "600px", margin: "auto" }}
                                        className="image-list-container"
                                    >
                                        {itemDataFrontend.map((item) => (
                                            <ImageListItem
                                                key={item.img}
                                            >
                                                {/* <p>{item.title}</p> */}
                                                <img
                                                    src={`${item.img}?w=150&fit=contain&auto=format`}
                                                    srcSet={`${item.img}?w=150&fit=contain&auto=format&dpr=2 2x`}
                                                    alt={item.title}
                                                    loading="lazy"
                                                    className="tech-image-list-item"
                                                />
                                            </ImageListItem>
                                        ))}
                                    </ImageList>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className='swiper-slide-technologies'>
                                <div className="technologies-card">
                                    <h1 className="card-title">Back-end</h1>
                                    <ImageList
                                        variant="woven"
                                        cols={3}
                                        gap={12}
                                        sx={{ width: "200px", height: "600px", margin: "auto" }}
                                        className="image-list-container"
                                    >
                                        {itemDataBackend.map((item) => (
                                            <ImageListItem
                                                key={item.img}
                                            >
                                                <img
                                                    src={`${item.img}?w=150&fit=contain&auto=format`}
                                                    srcSet={`${item.img}?w=150&fit=contain&auto=format&dpr=2 2x`}
                                                    alt={item.title}
                                                    loading="lazy"
                                                    className="tech-image-list-item"
                                                />
                                            </ImageListItem>
                                        ))}
                                    </ImageList>
                                </div>
                            </SwiperSlide>
                        </Swiper>


                    </div>
                    </motion.div>
                </div>
            </>

        </section >

    )
}

export default Technologies