
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { motion, MotionConfig } from "framer-motion"


export default function ActionAreaCard({img_path, title, description}) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1, type: "tween" }}
      className='card-grid'
  >
      <Card className='root_card' sx={{ maxWidth: 400 }}>
        <CardActionArea className='card_area'>
          <CardMedia
            component="img"
            height="150"
            image={`/images/${img_path}.jpg`}
            // alt=""
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </motion.div>
  );
}