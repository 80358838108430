import React from 'react'
import '../../App.scss'
import ContactUs from '../ContactUs'
import './Home.scss'


import HeroSection from '../HeroSection'
import Technologies from '../Technologies'
import Services from '../Services'
import Footer from '../Footer'
import Subscribe from '../Subscribe'


function Home() {
    return (
        <>
            <div className="home-section">
                <HeroSection />
                <Services />
                <Technologies />
                <ContactUs />
                <Subscribe/>
                <Footer />
            </div>
        </>
    )
}

export default Home