import './App.scss';
import Navbar from './components/Navbar';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';


// function App() {
//   return (
//     <>
//       <Router>
//         <Navbar />
//         <Home/>
//         <Routes>
//           <Route path='/' element={<Home/>} />
//         </Routes>
//       </Router>
//     </>
//   );
// }
function App() {
  return (
    <>
      <Navbar />
      <Home />
    </>
  );
}

export default App;
