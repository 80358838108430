import { Grid } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { json } from 'react-router-dom'
import { useMailChimp } from './mailchimp'
import Sparkles from './sparkle'
import './Subscribe.scss'
import { motion, MotionConfig } from "framer-motion"

const Status = {
    idle: 'IDLE',
    loading: 'LOADING',
    success: 'SUCCESS',
    error: 'ERROR'
}

function Subscribe() {
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const { subscribe, status, error, value } = useMailChimp("https://gmail.us21.list-manage.com/subscribe/post?")

    const handleSubmit = async (event) => {
        event.preventDefault()

        const data = new FormData(event.target)
        const U = "617df138aa100bde24258f97b"
        const ID = "16bc97dfd5"
        data.append("u", U)
        data.append("id", ID)
        data.append("NEWSLETTER", "yes")

        var object = {};
        data.forEach((value, key) => object[key] = value);

        if (object.EMAIL === '') return

        try {
            // console.log(object.FNAME)

            subscribe(object)
            // console.log(status)
            // if (status === Status.loading) {
            //     console.log('loading')
            // }

            // if (status === Status.error) {
            //     console.log('error', error)
            // }

            // if (value.includes('Already subscribed')) {
            //     console.log('already subscribed')
            // }
            // if (value) {

            //     return (
            //         <>
            //             <div className="success">
            //                 <h1>Thank you for subscribing!</h1>
            //             </div>
            //         </>
            //     )
            // }
        } catch (error) {
            // console.log(error)
            return
        }

    }




    const handleEmailChange = (event) => {
        const { value } = event.target
        setEmail(value)
    }

    const handleNameChange = (event) => {
        const { value } = event.target
        setName(value)
    }
    return (
        <div id="subscribe">
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}
            >
                {(status === "IDLE" || status === Status.loading) && (
                    <div className="subscribe-container">
                        <Grid container spacing={2} className='subscribe-form-grid'>
                            <Grid item xs={12} sm={12} md={6} className='newsletter-prompt-img'>
                                <img src="/images/subscribe.png" alt="" id="subscribe-img" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className='newsletter-input-info'>
                                <div className='newsletter-prompt'>
                                    <h1 className='subscribe-title'>Our newsletter</h1>
                                    <br />
                                    <p className='subscribe-paragraph'>Keep up to date with the latest tech industry trends and insights in the world of automation, artificial intelligence, and cloud computing by subscribing to our newsletter</p>
                                    <br /><br />
                                </div>
                                <form onSubmit={handleSubmit} className='subscribe-form'>
                                    <input
                                        aria-label="Name"
                                        name="FNAME"
                                        placeholder="Name"
                                        type="text"
                                        onChange={handleNameChange}
                                        value={name}
                                        className='subscribe-input'
                                    />
                                    <input
                                        aria-label="Email"
                                        name="EMAIL"
                                        placeholder="Email"
                                        required
                                        type="email"
                                        onChange={handleEmailChange}
                                        value={email}
                                        className='subscribe-input'
                                    />
                                    <button className='subscribe-btn'>Subscribe</button>
                                </form>
                            </Grid>

                        </Grid>

                    </div>
                )}
                {(status === "SUCCESS") && (
                    <>
                        <div className="subscribe-container">

                            <Grid container spacing={2} className='subscribe-form-grid'>
                                <Grid item xs={12} sm={12} md={6} className='newsletter-prompt-img'>
                                    <img src="/images/subscribe.png" alt="" id="subscribe-img" />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} className='newsletter-input-info'>
                                    <div className='newsletter-prompt'>
                                        <Sparkles><h1 className='subscribe-title'>Our newsletter</h1></Sparkles>
                                        <br />
                                        <Sparkles><p className='subscribe-paragraph'>Keep up to date with the latest tech industry trends and insights in the world of automation, artificial intelligence, and cloud computing by subscribing to our newsletter</p></Sparkles>
                                        <br /><br />

                                    </div>
                                    <Sparkles>
                                        <div className="subscribe-form star-container">
                                            <Sparkles><p className='subscribed-paragraph'>Thanks for subscribing!</p></Sparkles>
                                        </div>
                                    </Sparkles>
                                </Grid>
                            </Grid>
                        </div>
                    </>
                )}
            </motion.div>
        </div>

    )
}

export default Subscribe