import { Grid } from '@mui/material'

import '../App.scss'
import ActionAreaCard from './ServiceCard'
import './Services.scss'
import { motion, MotionConfig } from "framer-motion"
import { useState } from 'react'





function Services() {
    // const [useDelay, setUseDelay] = useState(false)

    return (
        <>
            <section id='services'>
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                >
                    <h1 className="section-title">Our Services</h1>
                </motion.div>
                <div className='services-parent'>
                    <Grid container spacing={2} alignItems="stretch" className='cards-container'>
                        <Grid item xs={12} sm={12} md={6} lg={3} className='card-grid'>
                            <ActionAreaCard
                                // delay={0.2}
                                img_path='extraction'
                                title='Data extraction and collection'
                                description='We can help you to automatically collect and extract data from websites and different kinds of sources for uses such as market research, price comparison, politics, and contact details.'
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3} className='card-grid'>

                            <ActionAreaCard
                                // delay={0.6}
                                img_path='web_dev'
                                title='Web development'
                                description='Let us build your custom web applications to meet your business requirements and improve efficiency, and customer experience.'
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3} className='card-grid'>
                            <ActionAreaCard
                                // delay={0.8}
                                img_path='data_mining'
                                title='Data mining and data storytelling'
                                description='We analyze your large sets of data to discover insights and give you an interactive visual representation of your data.'
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3} className='card-grid'>
                            <ActionAreaCard
                                // delay={1}
                                img_path='ml_training'
                                title='Machine Learning and Cloud Computing training'
                                description='Level-up your team with our custom training sessions and hands-on workshops. Your team will quickly gain knowledge and practical experience in machine learning algorithms and cloud platforms.'
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={3} className='card-grid'>
                            <ActionAreaCard
                                // delay={1.2}
                                img_path='real_estate'
                                title='Real estate data tracking'
                                description='Automatically fetch data from real estate or vacation rental platforms to track prices, availability, descriptions, amenities, and other details for potential buyers or clients.'
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3} className='card-grid'>
                            <ActionAreaCard
                                // delay={1.4}
                                img_path='rpa'
                                title='Robotic Process Automation'
                                description='We will help you to automate your repetitive and manual tasks to improve your efficiency and accuracy.'
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3} className='card-grid'>
                            <ActionAreaCard
                                // delay={0.4}
                                img_path='social_media'
                                title='Social media monitoring and tracking'
                                description='Get data from social media websites to track mentions of brands or persons and keywords, measure sentiments, and gather insights on customer behavior.'
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={3} className='card-grid'>
                            <ActionAreaCard
                                // delay={1.6}
                                img_path='ml_cloud_consultancy'
                                title='Machine Learning and Cloud Computing consultancy'
                                description='Get expert advice and consultancy services to solve your business problems using cloud computing solutions and artificial intelligence algorithms.'
                            />
                        </Grid>
                    </Grid>


                </div>
            </section>
        </>

    )
}

export default Services